import * as React from 'react';
import { Link } from 'gatsby';

import { cardItem, cardLink } from './card.module.scss';

const TipCard = ({ node }) => {
    return (
        <Link to={`/tip/${node.slug}`} className={cardLink}>
            <article className={cardItem}>
                <h3>
                    {node.frontmatter.title}
                </h3>
                <p>Posted: {node.frontmatter.date}</p>
                <p>
                    <em>{node.excerpt}</em>
                </p>
            </article>
        </Link>
    )
};

export default TipCard;
