import * as React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import {
    introTextContainer,
    tipList,
    loadMoreTipsButton,
    loadMoreTipsContainer,
    topicsLink,
} from './home.module.scss';
import SEO from '../components/seo';
import TipCard from '../components/tip-card';

const HomePage = ({ data }) => {
    const [tipLimit, setTipLimit] = React.useState(5);
    const maxTipListLength = data.allMdx.nodes.length;

    const onLoadMoreTipsClicked = React.useCallback(() => {
        const newLimit = Math.min(maxTipListLength, tipLimit + 10);
        setTipLimit(newLimit);
    }, [tipLimit, data]);

    return (
        <>
            <SEO />
            <Layout pageTitle={'Home'} showTitle={false}>
                <h2>Everyone loves fast web apps!</h2>

                <div className={introTextContainer}>
                    <p>
                        Here you'll find comprehensive tips and tutorials to
                        help you speed up your web app and keep it fast!
                    </p>
                    <p>
                        Explore the{' '}
                        <Link to="/topics" className={topicsLink}>
                            Topics page
                        </Link>{' '}
                        to find all tips categorized by subject matter or
                        recently published tips below!
                    </p>
                </div>

                <ul className={tipList}>
                    {data.allMdx.nodes.slice(0, tipLimit).map((node) => {
                        return (
                            <li key={node.id}>
                                <TipCard node={node} />
                            </li>
                        );
                    })}
                </ul>
                {tipLimit === maxTipListLength ? null : (
                    <div className={loadMoreTipsContainer}>
                        <button
                            onClick={onLoadMoreTipsClicked}
                            className={loadMoreTipsButton}
                        >
                            Load More Tips
                        </button>
                    </div>
                )}
            </Layout>
        </>
    );
};

export const query = graphql`
    query {
        allMdx(sort: { fields: frontmatter___date, order: DESC }) {
            nodes {
                frontmatter {
                    date(formatString: "MMMM D, YYYY")
                    title
                }
                id
                excerpt
                slug
            }
        }
    }
`;

export default HomePage;
